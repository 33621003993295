var exports = {};
var slice = Array.prototype.slice;
exports = iterativelyWalk;

function iterativelyWalk(nodes, cb) {
  if (!("length" in nodes)) {
    nodes = [nodes];
  }

  nodes = slice.call(nodes);

  while (nodes.length) {
    var node = nodes.shift(),
        ret = cb(node);

    if (ret) {
      return ret;
    }

    if (node.childNodes && node.childNodes.length) {
      nodes = slice.call(node.childNodes).concat(nodes);
    }
  }
}

export default exports;